'use client';

import { useView } from '@/lib/store/hooks';
import Image, { ImageProps } from '../Image';

type PageFeatureImageProps = ImageProps;

export default function PageFeatureImage({ ...props }: PageFeatureImageProps) {
  const page = useView();
  const entryImage = page?.entryImage ?? null;

  return <Image {...entryImage} {...props} alt="feature-image" />;
}
