'use client';

import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { fmtCategoryNames } from '@/lib/utils/format';
import { PageHeaderProps } from '.';
import ShareArticle from '../ShareArticle';
import Box, { BoxProps } from '../ui/Box';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import NextAnchor from '../ui/NextAnchor';
import Txt from '../ui/Txt';
import * as styles from './PageHeader.css';

type PageMetaProps = BoxProps<'div'> & Partial<PageHeaderProps>;

export default function PageMeta({ children, ...props }: PageMetaProps) {
  const page = useView() as Entry<'article'>;

  const profiles = page?.profileMultiple?.map((p) => {
    return { title: p.title, uri: p.uri };
  });
  const articleDate = page?.postDate;
  const articleCategory = fmtCategoryNames(page?.contentTypeCategorySingle);

  return (
    <Box cx={{ mT: 'md' }} {...props}>
      <Flex direction="row" alignItems="center" columnGap="3xs">
        <Txt as="span" variant="body">
          {children || articleCategory}
        </Txt>
        <Divider direction="vertical" />
        <Txt as="span" variant="body">
          {articleDate}
        </Txt>
      </Flex>
      <Flex direction="row" wrap>
        {profiles.map((p, i) => (
          <Txt as="span" variant="body" key={i}>
            {profiles.length - 1 === i && <Txt>&nbsp;</Txt>}
            <Txt>{i ? (i === profiles.length - 1 ? 'and ' : ', ') : 'By '}</Txt>
            <NextAnchor href={p.uri} className={styles.pageMetaFeature}>
              {p.title}
            </NextAnchor>
          </Txt>
        ))}
      </Flex>
      <Box cx={{ pT: '2xs' }}>
        <ShareArticle />
      </Box>
    </Box>
  );
}
