'use client';

import SearchBar from '@/components/SearchBar';
import Txt from '@/components/ui/Txt';
import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { useBreakpoints } from '@/theme';
import BackgroundImage from 'public/images/homepage-bg.png';
import Image from '../Image';
import Box from '../ui/Box';
import Grid from '../ui/Grid';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';
import PageSummary from './PageSummary';

const PageHeaderHome = () => {
  const { content, description } = useView() as Entry<'home'>;
  const { src, width, height } = BackgroundImage ?? {};
  const { sm, lg } = useBreakpoints() ?? {};
  const ratio = lg ? undefined : sm ? 'square' : 'portrait';

  const image = { src, width, height };

  return (
    <PageHeaderRoot
      variant="home"
      cx={{ pY: '5xl' }}
      afterGrid={
        <Grid cx={{ pY: 'xl' }}>
          <Grid.Col offsetMd={2} md={8}>
            <Txt html variant="xl" as="span" cx={{ textAlign: 'center' }}>
              {content}
            </Txt>
          </Grid.Col>
          <Grid.Col offsetMd={3} md={6}>
            <PageSummary variant="large" cx={{ textAlign: 'center', display: 'block' }} html>
              {description}
            </PageSummary>
            <SearchBar />
          </Grid.Col>
        </Grid>
      }>
      <Box className={styles.imageWrapper}>
        <Box className={styles.image}>
          <Image priority alt="background-image" ratio={ratio} {...image} />
        </Box>
      </Box>
    </PageHeaderRoot>
  );
};

export default PageHeaderHome;
