import { ENTRIES_BY_TYPE_QUERY } from '@/gql/queries/queries.gql';
import { Entry, EntryTypename, parseEntries } from '@/lib/parsers/entries';
import { useQuery } from '@apollo/client';
import { firstNonNullable } from '@liquorice/allsorts-craftcms-nextjs';
import { ContentByTypeQueryVariables } from '__generated__/graphql';

/**
 * Custom hook to fetch the latest articles based on the provided variables and type names.
 * @template TName - The type name of the entry.
 * @param {ContentByTypeQueryVariables} variables - The variables for the query.
 * @param {TName[] | TName} typeNames - The type names to filter the entries.
 * @returns {Object} - An object containing the result, loading state, and error state.
 */
export const useLatestEntries = <TName extends EntryTypename>(
  variables: ContentByTypeQueryVariables,
  typeNames: TName[] | TName = []
) => {
  const { data, loading, error } = useQuery(ENTRIES_BY_TYPE_QUERY, {
    variables,
  });
  if (error) return null && console.log(`An error occurred with the query: ${error.message}`);

  const result = parseEntries(data?.entries, typeNames);

  return {
    result,
    loading,
    error,
  };
};

// TODO: Get category IDs dynamically
const Categories = {
  news: {
    id: '552',
  },
  insight: {
    id: '554',
  },
  video: {
    id: '555',
  },
  podcast: {
    id: '556',
  },
  event: {
    id: '553',
  },
} as const;

type CategoryId = keyof typeof Categories;

/**
 * Custom hook to fetch the latest article by category.
 * @param {ContentByTypeQueryVariables} variables - The variables for the query.
 * @param {CategoryId} articleCategoryId - The category ID to filter the articles.
 * @returns {Object} - An object containing the entry, loading state, and error state.
 */
export const useLatestArticleByCategory = (
  variables: ContentByTypeQueryVariables,
  articleCategoryId: CategoryId
): { entry: Entry<'article'>; loading: boolean; error: undefined } | null => {
  const { data, loading, error } = useQuery(ENTRIES_BY_TYPE_QUERY, {
    variables: { articleCategoryId: Categories[articleCategoryId], limit: 1, ...variables },
  });
  if (error) return null && console.log(`An error occurred with the query: ${error.message}`);

  const result = parseEntries(data?.entries, 'article_Entry');

  const entry = firstNonNullable(result) as Entry<'article'>;

  return {
    entry,
    loading,
    error,
  };
};
