'use client';

import { parseDisplayRoleMode } from '@/lib/parsers/common';
import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { fmtCategoryNames, fmtRole } from '@/lib/utils/format';
import { useTranslations } from 'next-intl';
import Socials from '../Socials';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import PageFeatureImage from './PageFeatureImage';
import PageHeaderRoot from './PageHeaderRoot';
import PageTitle from './PageTitle';
import PageVcard from './PageVcard';

const PageHeaderProfile = () => {
  const page = useView() as Entry<'profile'> | undefined;
  const t = useTranslations('common');

  const {
    profileRoleSingle,
    displayRole,
    displayRoleMode,
    languageMultiple,
    phoneMobile,
    phoneOffice,
    firstName,
    email,
    socialLinks: socials,
  } = page ?? {};

  const roleCategoryName = fmtCategoryNames(profileRoleSingle);
  const role = fmtRole(roleCategoryName, parseDisplayRoleMode(displayRoleMode), displayRole);

  const languages = !!languageMultiple?.length
    ? `${t('fluentIn')}: ${fmtCategoryNames(languageMultiple)}`
    : null;

  return (
    <PageHeaderRoot variant="profile" colorSet="secondary" paper>
      <Grid
        cx={{
          direction: { md: 'row' },
          rowGap: { xs: 'lg', md: 'none' },
        }}>
        <Grid.Col md={6}>
          <PageFeatureImage fixedRatio />
        </Grid.Col>
        <Grid.Col md={6}>
          <Box cx={{ mY: 'sm', pL: { md: 'xl' } }}>
            <PageTitle variant="h3" autoMargin />
            <Txt as="p" variant="large">
              {role}
            </Txt>
            <Divider cx={{ color: 'neutralLight', mB: 'sm' }} />
            <Flex rowGap="2xs" alignItems="start">
              {languages && (
                <Txt as="span" variant="body">
                  {languages}
                </Txt>
              )}
              {phoneMobile && (
                <Btn variant="text" href={'tel:' + phoneMobile}>
                  {phoneMobile}
                </Btn>
              )}
              {phoneOffice && (
                <Btn variant="text" href={'tel:' + phoneOffice}>
                  {phoneOffice}
                </Btn>
              )}
              {email && firstName && (
                <Btn variant="text" href={'mailto:' + email}>
                  {t('email')} {firstName}
                </Btn>
              )}
              <Socials links={socials} includeName={true} />
              <PageVcard />
            </Flex>
          </Box>
        </Grid.Col>
      </Grid>
    </PageHeaderRoot>
  );
};

export default PageHeaderProfile;
