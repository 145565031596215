import { Sprinkles } from '@/theme/sprinkles.css';
import Breadcrumbs from '../Breadcrumbs';
import LanguageSelector from '../LanguageSelector';
import Box, { BoxProps } from '../ui/Box';
import Container, { ContainerProps } from '../ui/Container';
import Grid, { GridColProps } from '../ui/Grid';
import PageShim from './PageShim';
import PageTitle from './PageTitle';

export type PageHeaderRootProps = BoxProps<
  'header',
  {
    // PageTitleProps?: PageTitleProps;
    variant?: 'standard' | 'overview' | 'home' | 'profile' | 'article';
    maxWidth?: ContainerProps['maxWidth'];
    show?: {
      date?: boolean;
      author?: boolean;
    };
    beforeTitle?: React.ReactNode;
    afterGrid?: React.ReactNode;
    afterContainer?: React.ReactNode;
  }
>;

const PageHeaderRoot = ({
  // PageTitleProps,
  children,
  variant = 'standard',
  maxWidth,
  beforeTitle,
  afterGrid,
  afterContainer,
  cx: customCx,
  ...props
}: PageHeaderRootProps) => {
  const cx: Sprinkles = {
    pY: '5xl',
    position: 'relative',
    ...customCx,
  };
  const gridColProps: GridColProps = {
    cx: {
      display: 'flex',
      spacing: 'sm',
      flexDirection: 'column',
    },
    ...(variant === 'article' && {
      md: 8,
      offsetMd: 2,
    }),
  };

  const isHomePage = variant === 'home';
  const isProfilePage = variant === 'profile';

  const languageSelector = <LanguageSelector />;
  return (
    <Box as="header" {...props} cx={cx}>
      <PageShim />
      <Container maxWidth={maxWidth}>
        <Grid>
          <Grid.Col {...gridColProps}>
            {!isHomePage && <Breadcrumbs />}
            {beforeTitle}
            {!isHomePage && !isProfilePage && <PageTitle />}
            {children}
          </Grid.Col>
        </Grid>
        {afterGrid}
      </Container>
      {afterContainer}

      {languageSelector && (
        <Container maxWidth={maxWidth} cx={{ marginTop: 'lg' }}>
          {languageSelector}
        </Container>
      )}
    </Box>
  );
};

export default PageHeaderRoot;
