'use client';

import { useBreakpointMax } from '@/theme';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import PageHeaderRoot from './PageHeaderRoot';
import PageSummary from './PageSummary';
import { useTranslations } from 'next-intl';

const PageHeaderError = () => {
  const sm = useBreakpointMax('sm');
  const t = useTranslations('common');

  return (
    <PageHeaderRoot
      variant="standard"
      colorSet="white"
      paper
      afterGrid={
        <Flex direction="row" columnGap="sm" rowGap="sm" wrap cx={{ mT: 'md' }}>
          <Btn rounded={false} href="/" fullWidth={sm}>
            {t('returnHome')}
          </Btn>
          <Btn href="/contact/" variant="outlined" rounded={false} fullWidth={sm}>
            {t('contactUs')}
          </Btn>
        </Flex>
      }>
      <PageSummary variant="medium" cx={{ width: '60' }} />
    </PageHeaderRoot>
  );
};

export default PageHeaderError;
