'use client';

import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { useTranslations } from 'next-intl';
import CtaButton from '../CtaButton';
import Box, { BoxProps } from '../ui/Box';
import { BtnProps } from '../ui/Btn';

type PageVcardProps = BoxProps<
  'div',
  {
    BtnProps?: Omit<BtnProps<'a'>, 'href'>;
  }
>;

const PageVcard = ({ BtnProps, children, ...props }: PageVcardProps) => {
  const page = useView() as Entry<'profile'> | undefined;
  const t = useTranslations('common');
  const contact = page?.contactCard?.src ?? undefined;

  return (
    contact && (
      <Box {...props}>
        <CtaButton
          href={contact}
          {...BtnProps}
          endIcon={undefined}
          StartIconProps={{ name: 'download' }}>
          {t('contactCard') || children || t('download')}
        </CtaButton>
      </Box>
    )
  );
};

export default PageVcard;
