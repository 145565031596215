'use client';

import { useView } from '@/lib/store/hooks';
import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import CtaButton from '../CtaButton';

const ShareArticle = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const page = useView();

  const url = page?.url ?? '';
  const title = (page?.pageTitle || page?.title) ?? '';

  // Linkedin
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

  // Twitter
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;

  // Copy to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    // alert('Link copied to clipboard');
    setAnchorEl(null);
  };

  const t = useTranslations('social');

  return (
    <>
      <CtaButton
        endIcon={undefined}
        startIcon="share"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {t('share')}
      </CtaButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={handleClose}>
          <CtaButton endIcon={undefined} target="_blank" onClick={handleClose} href={linkedinUrl}>
            {t('linkedin')}
          </CtaButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CtaButton endIcon={undefined} target="_blank" onClick={handleClose} href={twitterUrl}>
            {t('twitter')}
          </CtaButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CtaButton endIcon={undefined} onClick={handleCopyLink}>
            {t('copy')}
          </CtaButton>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ShareArticle;
