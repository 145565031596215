import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import { EntryCardProps } from './EntryCardProps';

export type FeaturedCardProps = EntryCardProps<'article'>;

export const FeaturedCard = ({ item, ...props }: FeaturedCardProps) => {
  const {
    uri: href,
    title,
    summary: description,
    pageTitle,
    postDate,
    contentTypeCategorySingle,
    entryImage: image,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  return (
    <Card
      elevation={false}
      paper
      {...props}
      item={{
        href,
        title: pageTitle || title,
        meta: [contentTypeName, postDate],
        description,
        image,
      }}>
      <Card.Image ratio="landscape" transform="fullFit" />
      <Card.Body cx={{ pT: 'md', rowGap: 'xs' }}>
        <Card.Meta />
        <Card.Title variant="h5" as="span" />
        <Card.Cta variant="text" size="small" endIcon="arrowRight" cx={{ fontSize: 'medium' }} />
      </Card.Body>
    </Card>
  );
};
