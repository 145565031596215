'use client';

import SkeletonCards from '@/components/SkeletonCards';
import { useLatestEntries } from '@/lib/getters/useLatestEntries';
import CardGrid from '../CardGrid';
import { FeaturedCard } from '../EntryCard/EntryCard.Featured';
import Box from '../ui/Box';
import PageHeaderRoot from './PageHeaderRoot';

const PageHeaderArticleIndex = () => {
  const { result, loading, error } =
    useLatestEntries({ limit: 2, section: 'article', orderBy: 'postDate desc' }, 'article_Entry') ??
    {};

  return (
    <PageHeaderRoot
      colorSet="secondary"
      paper
      afterGrid={
        <Box cx={{ mT: '2xl' }}>
          {!error &&
            (loading ? (
              <SkeletonCards
                amount={2}
                md={6}
                SkeletonItems={{
                  bgcolor: 'neutralLight',
                  items: [
                    {
                      variant: 'rounded',
                      height: 300,
                      width: '100%',
                    },
                    {
                      variant: 'rounded',
                      height: 50,
                      width: '100%',
                    },
                    {
                      variant: 'rounded',
                      height: 20,
                      width: '50%',
                    },
                  ],
                  cx: { rowGap: 'sm' },
                }}
              />
            ) : (
              result && (
                <CardGrid
                  md={6}
                  items={result}
                  Component={FeaturedCard}
                  disableFullHeight
                  rowGutter={false}
                  cx={{ rowGap: { xs: 'xl', lg: '3xl' } }}
                />
              )
            ))}
        </Box>
      }></PageHeaderRoot>
  );
};

export default PageHeaderArticleIndex;
