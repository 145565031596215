'use client';

import React from 'react';
import Txt, { TxtProps } from '../ui/Txt';
import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';

type PageSummaryProps = TxtProps<'span'> & Partial<PageHeaderProps>;

export default function PageSummary({ children, ...props }: PageSummaryProps) {
  const page = useView();
  const summary = page?.summary ?? null;
  const content = summary || children;

  return (
    content && (
      <Txt as="span" variant="large" {...props}>
        {content}
      </Txt>
    )
  );
}
